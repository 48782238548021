import qs from 'qs'

export const queryAboutPageThumbnail = () => {
  const query = qs.stringify(
    {
      populate: {
        ...queryFragmentSingleTypeDefault
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `about-page?${query}`
}
