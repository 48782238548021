<template>
  <div :class="$style.root">
    <div :class="$style.introduction">
      <div
        :class="$style.about"
        v-if="title && introduction"
      >
        <LayoutLinkWithBarComponent :to="{ name: 'program' }">
          {{ title }}
        </LayoutLinkWithBarComponent>
        <CoreTextComponent :string="introduction" />
      </div>
    </div>

    <SharedThumbnailsProgramsComponent
      v-if="programEntriesData?.data?.length"
      :thumbnails="programEntriesData?.data"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  programsPageData: {
    type: Object,
    required: false
  },
  programEntriesData: {
    type: Object,
    required: false
  },
})

const localizedProgramsPageData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.programsPageData))
})

const title = computed(() => {
  return localizedProgramsPageData?.value?.titles?.current_locale || localizedProgramsPageData?.value?.titles?.i18n_string_en
})

const introduction = computed(() => {
  return localizedProgramsPageData?.value?.introductions?.current_locale || localizedProgramsPageData?.value?.introductions?.i18n_richtext_en
})
</script>

<style module>
.root {
}

.introduction {
  padding: 0 var(--unit--horizontal--double);

  margin-bottom: var(--unit--vertical--tripple);
}

.about {
  max-width: var(--container--default--without-padding);
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: var(--unit--vertical);
}
</style>
