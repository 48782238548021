import qs from 'qs'

export const queryParticipantsPageCurrentParticipants = () => {
  const query = qs.stringify(
    {
      populate: {
        current_participant_entries: {
          fields: ['title', 'slug']
        }
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `participants-page?${query}`
}
