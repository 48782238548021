<template>
  <i18nLink
    :class="$style.root"
    :to="to"
    :data-active="mouseEnteredDebounced ? '' : null"
    @mouseenter="mouseEntered = true"
    @mouseleave="mouseEntered = false"
  >
    <span :class="$style.bar" />
    <span :class="$style.text">
      <slot />
    </span>
  </i18nLink>
</template>

<script setup>
const props = defineProps({
  to: Object
})

const mouseEntered = ref(false)
const mouseEnteredDebounced = refDebounced(mouseEntered, 200)
</script>

<style module>
.root {
  display: inline-flex;
  margin-right: auto;

  gap: .5ch;
}

.root[data-active] {
  padding-right: 8ch;
  color: var(--color--accent);

  gap: .1ch;
}

.root[data-active] .bar {
  width: var(--fs, 1em);
  transform: rotate(-90deg);
}

.bar {
  width: 8ch;
  height: var(--stroke--thick);
  background-color: currentColor;

  display: inline-block;

  border-radius: 9999px;

  transition: width .2s ease-out, transform .2s ease-out;

  pointer-events: none;

  margin: auto;
}

.text {
  transform: translateY(-.05em);
}
</style>
