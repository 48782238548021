<template>
  <div :class="$style.root">
    <div
      :class="$style.bulletinBoard"
      v-if="bulletinBoardText"
    >
      <div :class="$style.bulletinBoardText">
        <CoreTextComponent :string="bulletinBoardText" />
        <LayoutHorizontalLineComponent />

        <SharedOpeningTimesComponent
          :isOpen="openingTimesData?.isOpen"
          :openingTimes="openingTimesData?.data"
          fontSizeOption="small"
        />
      </div>
    </div>

    <LayoutGridComponent :class="$style.grid">
      <div
        :class="$style.about"
        v-if="aboutTitle && aboutText"
      >
        <LayoutLinkWithBarComponent :to="{name: 'about'}">
          {{ aboutTitle }}
        </LayoutLinkWithBarComponent>
        <CoreTextComponent :string="aboutText" />
      </div>

      <SharedFellowThumbnailContainerComponent
        v-if="currentParticipantEntries?.length"
        v-for="participant in currentParticipantEntries"
        status="current"
      >
        <SharedThumbnailFellowComponent :data="participant" />
      </SharedFellowThumbnailContainerComponent>

      <CoreImageComponent
        v-if="localizedPageData?.cover_image?.data?.attributes && !currentParticipantEntries?.length"
        :image="localizedPageData?.cover_image?.data?.attributes"
        :class="$style.coverImage"
      />
    </LayoutGridComponent>
  </div>
</template>

<script setup>
const props = defineProps({
  pageData: Object,
  aboutPageData: {
    type: Object,
    required: false
  },
  participantsPageData: {
    type: Object,
    required: false
  }
})

const localizedPageData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.pageData))
})

const bulletinBoardText = computed(() => {
  return localizedPageData?.value?.bulletin_board_texts?.current_locale || localizedPageData?.value?.bulletin_board_texts?.i18n_richtext_en
})

const localizedAboutPageData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.aboutPageData))
})

const aboutTitle = computed(() => {
  return localizedAboutPageData?.value?.titles?.current_locale || localizedAboutPageData?.value?.titles?.i18n_string_en
})

const aboutText = computed(() => {
  return localizedAboutPageData?.value?.introductions?.current_locale || localizedAboutPageData?.value?.introductions?.i18n_richtext_en
})

const currentParticipantEntries = computed(() => {
  return props?.participantsPageData?.data?.attributes?.current_participant_entries?.data
})

// 2
const mainStore = useMainStore()
const openingTimesData = await mainStore?.fetchOpeningTimesData()
</script>

<style module>
.root {
  padding: var(--unit--vertical--tripple) 0;
  background-color: var(--color--pink--light);

  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: var(--unit--vertical--sextuple);
}

.bulletinBoard {
  display: flex;
  justify-content: center;
}

.bulletinBoardText {
  composes: container--medium from global;

  text-align: center;

  display: flex;
  flex-direction: column;
  gap: var(--unit--vertical);
}

.grid {
  padding: 0 var(--unit--horizontal--double);
}

.about {
  max-width: var(--container--default--without-padding);
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: var(--unit--vertical);
}

.coverImage {
  max-width: var(--container--default--without-padding);
  width: 100%;
  margin: auto;
}
</style>
