<template>
  <div>
    <section>
      <PagesIndexClockComponent />
    </section>

    <section :class="$style.section">
      <PagesIndexProgramsComponent
        v-if="programsPageData?.data && programEntriesData?.data?.length"
        :programsPageData="props.programsPageData"
        :programEntriesData="props.programEntriesData"
      />
    </section>

    <section :class="$style.about">
      <PagesIndexIntroductionComponent
        :pageData="props.pageData"
        :aboutPageData="props.aboutPageData"
        :participantsPageData="props.participantsPageData"
      />
    </section>

    <section :class="$style.section">
      <PagesIndexPublicationsComponent
        v-if="publicationsPageData?.data && publicationEntriesData?.data?.length"
        :publicationsPageData="props.publicationsPageData"
        :publicationEntriesData="props.publicationEntriesData"
      />
    </section>
  </div>
</template>

<script setup>
const props = defineProps({
  pageData: Object,
  aboutPageData: {
    type: Object,
    required: false
  },
  participantsPageData: {
    type: Object,
    required: false
  },
  programsPageData: {
    type: Object,
    required: false
  },
  programEntriesData: {
    type: Object,
    required: false
  },
  publicationsPageData: {
    type: Object,
    required: false
  },
  publicationEntriesData: {
    type: Object,
    required: false
  }
})

const localizedData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.pageData))
})
</script>

<style module>
.section:not(:last-child) {
  margin-bottom: var(--unit--vertical--sextuple);
}

.about {
  margin-bottom: var(--unit--vertical--tripple);
}
</style>
