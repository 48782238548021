<template>
	<svg version="1.1" id="uuid-d47a8c76-a9da-4bdb-8e0a-44819fa2a681" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1080 1080" style="enable-background:new 0 0 1080 1080;" xml:space="preserve">
	  <path
			:class="$style.hour"
			:data-current="currentHour === 1 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(1) ? '' : null"
			d="M671.1,19.5c1.1-1.1,2.4-1.6,3.9-1.6s2.8,0.5,3.9,1.6s1.6,2.4,1.6,3.9V97c0,1.5-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9V23.4C669.5,21.9,670,20.6,671.1,19.5z"
		/>
	  <path
			:class="$style.hour"
			:data-current="currentHour === 2 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(2) ? '' : null"
			d="M779.6,94.9c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9c0-7.4,2.3-13.2,6.8-17.5s10.1-6.4,16.6-6.4s12.3,2.4,16.7,7.2c4.4,4.8,6.6,10.7,6.6,17.6c0,4.4-1,8.2-3.1,11.4c-2.1,3.2-5.3,6.5-9.8,9.8c-0.6,0.5-1.5,1.2-2.6,2.1c-7.5,5.6-12.5,9.9-14.8,12.9c-2.4,3-3.8,7.5-4.4,13.6H814c1.5,0,2.8,0.5,3.9,1.6c1.1,1.1,1.6,2.4,1.6,3.9s-0.5,2.8-1.6,3.9s-2.4,1.6-3.9,1.6h-37.5c-1.3,0-2.5-0.5-3.7-1.6s-1.8-2.4-1.8-3.9c0-2.5,0.1-4.9,0.4-7.1c0.3-2.2,0.6-4.2,0.9-6c0.3-1.8,0.9-3.6,1.8-5.4c0.8-1.8,1.6-3.3,2.2-4.5c0.7-1.2,1.7-2.6,3.1-4.2c1.4-1.6,2.6-2.8,3.5-3.8s2.3-2.2,4.2-3.7c1.9-1.5,3.5-2.7,4.6-3.6c1.2-0.9,2.9-2.2,5.2-4c0.3-0.2,0.5-0.3,0.6-0.3c2.7-2.1,4.6-3.7,5.8-4.9s1.9-2.3,2.2-3.3c0.3-1,0.5-2.4,0.5-4.2c0-4.1-1.2-7.5-3.5-10s-5.2-3.8-8.8-3.8c-8.3,0-12.4,4.3-12.4,12.9C781.2,92.5,780.7,93.8,779.6,94.9L779.6,94.9z"
		/>
	  <path
			:class="$style.hour"
			:data-current="currentHour === 3 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(3) ? '' : null"
			d="M877,207.9c0.7-0.3,1.4-0.5,2.1-0.5s1.4,0.1,2.1,0.4c0.7,0.3,1.3,0.7,1.8,1.2c0.5,0.5,0.9,1.1,1.2,1.7c1.3,3,3.1,5.4,5.5,7.1c2.4,1.8,4.9,2.6,7.7,2.6c3.9,0,7.4-1.7,10.4-5.1c3-3.4,4.5-7.5,4.5-12.4c0-5.4-1.3-9.7-3.9-13c-2.6-3.3-6-4.9-10.1-4.9c-1.9,0-4.2,0.6-6.8,1.9c-0.7,0.4-1.5,0.6-2.4,0.6c-0.5,0-1.1-0.1-1.7-0.2c-0.2-0.1-0.5-0.3-0.8-0.4s-0.5-0.2-0.8-0.4s-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.4-0.6-0.6s-0.3-0.5-0.4-0.8h-0.1v-0.1c-0.4-0.7-0.6-1.5-0.6-2.4c0-0.5,0.1-1.1,0.2-1.7c0.2-0.6,0.6-1.2,1-1.8s1-1,1.7-1.3c8.3-4.4,14.1-11.2,17.5-20.2h-26.7c-1.5,0-2.8-0.5-3.9-1.6s-1.6-2.4-1.6-3.9s0.5-2.8,1.6-3.9s2.4-1.6,3.9-1.6h33.8c1.4,0,2.6,0.5,3.8,1.6s1.7,2.4,1.7,3.9v0.7c-0.1,0.4-0.2,0.9-0.3,1.5s-0.4,1.8-0.9,3.6s-1,3.6-1.7,5.3s-1.7,3.8-3.1,6.1s-2.9,4.5-4.6,6.6c5.4,1.8,9.7,5.4,12.7,10.6c3,5.2,4.5,10.9,4.5,17.1c0,7.7-2.5,14.4-7.5,20.1c-5,5.6-11.2,8.4-18.5,8.4c-5.1,0-9.8-1.5-14-4.6c-4.2-3.1-7.3-7-9.3-11.8c-0.1-0.1-0.1-0.3-0.2-0.5c0-0.1-0.1-0.3-0.1-0.4s-0.1-0.2-0.1-0.4c0-0.1-0.1-0.3-0.1-0.5v-0.5c0-1.1,0.3-2.1,0.9-3.1S876.1,208.2,877,207.9L877,207.9z"
		/>
	  <path
			:class="$style.hour"
			:data-current="currentHour === 4 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(4) ? '' : null"
			d="M1002.6,298.8c1.1,1.1,1.6,2.4,1.6,3.9s-0.5,2.8-1.6,3.9s-2.4,1.6-3.9,1.6h-4.8v20.2c0,1.5-0.5,2.8-1.6,3.9s-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9v-20.2h-28.3c-1.5,0-2.8-0.6-3.9-1.8c-1.1-1.2-1.6-2.4-1.6-3.7v-7.1c0-1.1,0.3-2.2,0.9-3.1l28-40.7c1.2-1.6,2.7-2.4,4.6-2.4h5.8c1.5,0,2.8,0.6,3.9,1.8s1.6,2.4,1.6,3.7v42.3h4.8C1000.3,297.2,1001.6,297.7,1002.6,298.8L1002.6,298.8z M982.9,297.2v-32.9l-22.6,32.9H982.9z"
		/>
	  <path
			:class="$style.hour"
			:data-current="currentHour === 5 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(5) ? '' : null"
			d="M1005,430.3c0.2-0.1,0.5-0.1,0.7-0.2c0.2-0.1,0.5-0.1,0.7-0.2s0.5-0.1,0.8-0.1c1.1,0,2.1,0.3,3,0.9c0.9,0.6,1.6,1.4,2.1,2.4c0.9,2.1,2.1,3.9,3.5,5.4s2.9,2.7,4.5,3.5c1.6,0.8,3.3,1.1,4.9,1.1c3.8,0,7.2-1.9,10.2-5.7s4.6-8.6,4.6-14.5s-1.5-10.8-4.6-14.5s-6.5-5.7-10.2-5.7c-2.3,0-4.5,0.6-6.7,1.9c-2.1,1.3-4.1,3.2-5.9,5.8c-0.4,0.5-0.8,1-1.3,1.3s-1,0.6-1.5,0.8c-0.5,0.2-1.1,0.3-1.7,0.3c-1.3,0-2.5-0.4-3.5-1.3c-1.5-1.2-2.2-2.8-2.1-4.7l2.9-33.1c0.2-1.5,0.8-2.7,1.8-3.6s2.3-1.4,3.7-1.4h29.4c1.5,0,2.8,0.5,3.9,1.6s1.6,2.4,1.6,3.9s-0.5,2.8-1.6,3.9s-2.4,1.6-3.9,1.6h-24.4l-1.3,14.4c1.6-0.8,3.3-1.4,5.1-1.8s3.6-0.6,5.3-0.6c7.5,0,13.7,3.1,18.6,9.4c4.9,6.2,7.3,13.5,7.3,21.9s-2.4,15.6-7.3,21.9c-4.9,6.2-11.1,9.4-18.6,9.4c-5.1,0-9.6-1.5-13.7-4.6s-7.2-7.1-9.3-12c-0.3-0.7-0.5-1.4-0.5-2.2c0-1.1,0.3-2.1,0.9-3S1003.9,430.8,1005,430.3L1005,430.3z"
		/>
	  <path
			:class="$style.hour"
			:data-current="currentHour === 6 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(6) ? '' : null"
			d="M1028.4,536c2.1-1.8,4.5-3.2,7.2-4.2c2.7-1,5.6-1.4,8.6-1.4c7.1,0,13.1,2.6,17.9,7.9s7.2,11.5,7.2,18.5s-2.2,12.9-6.6,18.3s-10.3,8.1-17.8,8.1c-2.7,0-5.4-0.6-8.1-1.7c-2.7-1.2-5.4-2.9-8-5.3c-2.6-2.4-4.9-5.8-6.8-10.2c-2-4.4-3.3-9.4-4.1-15.2c-0.2-1.7-0.3-3.4-0.4-5.2c-0.1-1.8-0.2-3.7-0.2-5.6c0-7.4,0.9-14.1,2.6-19.9s4.1-10.3,6.9-13.6c2.8-3.2,5.8-5.6,8.8-7.3c3.1-1.6,6.1-2.5,9.2-2.5c2.1,0,4.2,0.2,6.1,0.8c1.9,0.5,3.6,1.2,4.9,2.1s2.6,1.7,3.7,2.5s2,1.7,2.7,2.7s1.2,1.7,1.5,2.1s0.5,0.8,0.8,1.1c0.2,0.4,0.3,0.8,0.4,1.2s0.2,0.9,0.2,1.3c0,2.2-1,3.8-2.9,4.8c-0.8,0.5-1.7,0.7-2.5,0.7c-1,0-1.9-0.2-2.9-0.8s-1.6-1.2-2.1-2.1c-0.3-0.5-0.7-1.1-1.3-1.7s-1.6-1.4-3.3-2.3c-1.7-0.9-3.4-1.4-5.3-1.4c-0.5,0-1.2,0.1-1.8,0.3s-1.6,0.6-2.6,1.1c-1,0.5-2.1,1.2-3.1,2.1s-2,2-3,3.5c-1,1.5-1.9,3.2-2.7,5.1s-1.5,4.2-2.1,7S1028.5,532.8,1028.4,536L1028.4,536z M1031.1,559.5c0.9,2.6,2,4.8,3.3,6.6c1.3,1.8,2.6,3.1,3.8,3.9s2.5,1.4,3.5,1.7s2.1,0.5,3.1,0.5c4,0,7.2-1.5,9.7-4.5c2.4-3,3.7-6.6,3.7-10.9s-1.4-7.8-4.2-10.9c-2.8-3-6.1-4.5-9.8-4.5c-4.1,0-7.3,1.3-9.6,3.9s-3.4,7.1-3.4,13.6L1031.1,559.5L1031.1,559.5z"
		/>
	  <path
			:class="$style.hour"
			:data-current="currentHour === 7 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(7) ? '' : null"
			d="M1001.7,635.8c-1.1-1.1-1.6-2.4-1.6-3.9s0.5-2.8,1.6-3.9s2.4-1.6,3.9-1.6h41.3c1.5,0,2.8,0.6,3.9,1.8c1.1,1.2,1.6,2.4,1.6,3.7v6.7c0,0.6-0.1,1.2-0.3,1.8s-0.5,1.1-0.9,1.5s-0.7,0.8-1,1.2c-0.5,0.2-1,0.6-1.6,1.1s-1.7,1.6-3.3,3.3s-3.1,3.5-4.5,5.5s-3,4.6-4.7,8s-3.2,6.9-4.4,10.7c-1.2,3.8-2.3,8.3-3.1,13.6c-0.8,5.3-1.3,10.8-1.3,16.7v3.7c0,1.5-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9v-3.7c0-7,0.6-13.8,1.7-20.2c1.2-6.4,2.6-11.9,4.2-16.3c1.7-4.4,3.7-8.6,5.9-12.6s4.3-7,6.1-9.2s3.6-4.2,5.5-6h-34.1C1004.1,637.4,1002.8,636.8,1001.7,635.8L1001.7,635.8z"
		/>
	  <path
			:class="$style.hour"
			:data-current="currentHour === 8 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(8) ? '' : null"
			d="M993.3,786.2c2,1.6,3.7,3.5,5.1,5.8c1.4,2.3,2.4,4.5,3,6.8s0.9,4.7,0.9,7.1c0,6.9-2.3,12.9-7,18c-4.7,5.1-10.9,7.7-18.7,7.7s-13.8-2.8-18.6-8.3s-7.2-11.8-7.2-18.8c0-7.8,2.8-14.4,8.3-19.9c-4.5-4.5-6.8-10-6.8-16.4s2.3-11.9,7-16.3s10.4-6.7,17.2-6.7s12.2,2.2,17,6.6s7.2,9.9,7.2,16.4C1000.9,774.3,998.4,780.3,993.3,786.2L993.3,786.2z M969.1,790.6c-1.8,1.3-3.5,3.1-4.9,5.3c-1.5,2.3-2.2,5.1-2.2,8.6c0,4.2,1.4,8,4.2,11.2s6.3,4.9,10.5,4.9c4.7,0,8.3-1.4,10.9-4.2s3.8-6.3,3.8-10.5c0-2.8-0.7-5.3-2-7.6c-1.3-2.3-3.2-4-5.6-5.1c-1.8-0.8-3.9-1.3-6.2-1.3c-1.5,0-2.9-0.1-4.3-0.3C971.8,791.3,970.4,791,969.1,790.6L969.1,790.6z M982.6,781.3c1.1-1.1,2.1-2.2,3.2-3.5s2-2.8,2.8-4.6c0.8-1.8,1.3-3.5,1.3-5c0-3.2-1.3-6-3.8-8.4c-2.6-2.4-5.7-3.6-9.4-3.6c-4,0-7.2,1.2-9.6,3.5c-2.4,2.3-3.6,5.2-3.6,8.5c0,4.6,2,8.1,5.9,10.5c2.4,1.5,5.1,2.2,8.3,2.2C979.3,780.9,980.9,781,982.6,781.3L982.6,781.3z"
		/>
	  <path
			:class="$style.hour"
			:data-current="currentHour === 9 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(9) ? '' : null"
			d="M912.5,895.5c-4.1,3.7-9.3,5.5-15.6,5.5c-7.1,0-13.1-2.6-17.9-7.9s-7.2-11.5-7.2-18.5s2.2-12.9,6.5-18.3c4.3-5.4,10.2-8.1,17.8-8.1c2.7,0,5.4,0.6,8.2,1.8c2.8,1.2,5.4,3,8,5.4s4.9,5.8,6.8,10.3c2,4.4,3.3,9.5,4,15.3c0.4,3.3,0.6,6.8,0.6,10.5c0,7.4-0.9,14.1-2.6,19.9s-4.1,10.3-6.9,13.6c-2.8,3.2-5.8,5.7-8.8,7.3c-3.1,1.7-6.1,2.5-9.2,2.5c-2.5,0-4.9-0.4-7.2-1.2s-4.1-1.6-5.5-2.6s-2.6-2.1-3.8-3.3c-1.2-1.3-1.9-2.2-2.3-2.7c-0.4-0.5-0.7-1-0.9-1.5c-0.4-0.8-0.6-1.6-0.6-2.5c0-2.2,1-3.8,2.9-4.8c0.8-0.5,1.7-0.7,2.5-0.7c1,0,2,0.2,2.9,0.8s1.6,1.2,2.1,2.1c0.3,0.5,0.7,1.1,1.3,1.7c0.5,0.6,1.6,1.4,3.3,2.3s3.4,1.4,5.3,1.4c0.6,0,1.4-0.2,2.2-0.5s1.9-0.8,3.2-1.5s2.4-1.7,3.6-3.1s2.3-3,3.3-5s2-4.5,2.7-7.6C911.8,902.7,912.3,899.2,912.5,895.5L912.5,895.5z M909.8,872.1c-0.9-2.6-2-4.8-3.3-6.7s-2.6-3.2-3.9-4s-2.5-1.4-3.6-1.8s-2-0.5-3-0.5c-4,0-7.2,1.5-9.6,4.5c-2.4,3-3.6,6.6-3.6,10.9s1.4,7.8,4.2,10.9c2.8,3,6.1,4.5,9.8,4.5c4.1,0,7.3-1.3,9.6-3.9c2.3-2.6,3.4-7.1,3.4-13.6L909.8,872.1L909.8,872.1z"
		/>
	  <g
			:class="$style.hour"
			:data-current="currentHour === 10 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(10) ? '' : null"
		>
	  	<path d="M756.3,929.6c1.1-1.1,2.4-1.6,3.9-1.6s2.8,0.5,3.9,1.6c1.1,1.1,1.6,2.4,1.6,3.9v73.6c0,1.5-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9v-73.6C754.7,932,755.2,930.7,756.3,929.6z"/>
	  	<path d="M831.7,949.5c2.1,6.5,3.2,13.4,3.2,20.8s-1.1,14.3-3.2,20.8c-2.1,6.5-5.4,11.8-9.8,16.1c-4.4,4.2-9.5,6.4-15.2,6.4s-10.8-2.1-15.2-6.4s-7.7-9.6-9.9-16.1c-2.2-6.5-3.3-13.4-3.3-20.8s1.1-14.3,3.3-20.8c2.2-6.5,5.5-11.8,9.9-16.1s9.5-6.4,15.2-6.4s10.8,2.1,15.2,6.4S829.6,943.1,831.7,949.5z M818.3,993.4c3.7-6,5.6-13.8,5.6-23.1s-1.9-17-5.6-23.1c-3.7-6-7.6-9.1-11.7-9.1s-8,3-11.7,9.1s-5.6,13.8-5.6,23.1s1.9,17,5.6,23.1s7.7,9.1,11.7,9.1S814.6,999.4,818.3,993.4z"/>
	  </g>
	  <g
			:class="$style.hour"
			:data-current="currentHour === 11 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(11) ? '' : null"
		>
	  	<path d="M655.2,979.2c1.1-1.1,2.4-1.6,3.9-1.6s2.8,0.5,3.9,1.6c1.1,1.1,1.6,2.4,1.6,3.9v73.6c0,1.5-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9v-73.6C653.6,981.6,654.2,980.3,655.2,979.2z"/>
	  	<path d="M687,979.2c1.1-1.1,2.4-1.6,3.9-1.6s2.8,0.5,3.9,1.6s1.6,2.4,1.6,3.9v73.6c0,1.5-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9v-73.6C685.3,981.6,685.9,980.3,687,979.2z"/>
	  </g>
	  <g
			:class="$style.hour"
			:data-current="currentHour === 12 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(12) ? '' : null"
		>
	  	<path d="M509.8,996.6c1.1-1.1,2.4-1.6,3.9-1.6s2.8,0.5,3.9,1.6c1.1,1.1,1.6,2.4,1.6,3.9v73.6c0,1.5-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9v-73.6C508.2,999,508.7,997.7,509.8,996.6z"/>
	  	<path d="M544.8,1021.9c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9c0-7.4,2.3-13.2,6.8-17.5s10.1-6.4,16.6-6.4s12.3,2.4,16.7,7.2c4.4,4.8,6.6,10.7,6.6,17.6c0,4.4-1,8.2-3.1,11.4c-2.1,3.2-5.3,6.5-9.8,9.8c-0.6,0.5-1.5,1.2-2.6,2.1c-7.5,5.6-12.5,9.9-14.8,12.9c-2.4,3-3.8,7.5-4.4,13.6h31.7c1.5,0,2.8,0.5,3.9,1.6c1.1,1.1,1.6,2.4,1.6,3.9s-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6h-37.5c-1.3,0-2.5-0.5-3.7-1.6s-1.8-2.4-1.8-3.9c0-2.5,0.1-4.9,0.4-7.1c0.3-2.2,0.6-4.2,0.9-6c0.3-1.8,0.9-3.6,1.8-5.4s1.6-3.3,2.2-4.5c0.7-1.2,1.7-2.6,3.1-4.2c1.4-1.6,2.6-2.8,3.5-3.8c0.9-1,2.3-2.2,4.2-3.7c1.9-1.5,3.5-2.7,4.6-3.6c1.2-0.9,2.9-2.2,5.2-4c0.3-0.2,0.5-0.3,0.6-0.3c2.7-2.1,4.6-3.7,5.8-4.9c1.2-1.2,1.9-2.3,2.2-3.3c0.3-1,0.5-2.4,0.5-4.2c0-4.1-1.2-7.5-3.5-10s-5.2-3.8-8.8-3.8c-8.3,0-12.4,4.3-12.4,12.9C546.4,1019.5,545.9,1020.8,544.8,1021.9L544.8,1021.9z"/>
	  </g>
	  <g
			:class="$style.hour"
			:data-current="currentHour === 13 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(13) ? '' : null"
		>
	  	<path d="M380.6,978.8c1.1-1.1,2.4-1.6,3.9-1.6s2.8,0.5,3.9,1.6c1.1,1.1,1.6,2.4,1.6,3.9v73.6c0,1.5-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9v-73.6C379,981.1,379.6,979.8,380.6,978.8z"/>
	  	<path d="M410.3,1039c0.7-0.3,1.4-0.5,2.1-0.5s1.4,0.1,2.1,0.4c0.7,0.3,1.3,0.7,1.8,1.2c0.5,0.5,0.9,1.1,1.1,1.7c1.3,3,3.1,5.4,5.5,7.1c2.4,1.8,4.9,2.6,7.7,2.6c3.9,0,7.4-1.7,10.4-5.1c3-3.4,4.5-7.5,4.5-12.4c0-5.4-1.3-9.7-3.9-13c-2.6-3.3-6-4.9-10.1-4.9c-1.9,0-4.2,0.7-6.8,2c-0.7,0.4-1.5,0.6-2.4,0.6c-0.5,0-1.1-0.1-1.7-0.2c-0.2-0.2-0.5-0.3-0.8-0.4s-0.5-0.2-0.8-0.4s-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.4-0.6-0.6s-0.3-0.5-0.4-0.8h-0.1v-0.1c-0.4-0.7-0.6-1.5-0.6-2.4c0-0.5,0.1-1.1,0.2-1.7c0.2-0.6,0.6-1.2,1-1.8s1-1,1.7-1.3c8.3-4.4,14.1-11.2,17.5-20.2h-26.7c-1.5,0-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9s0.5-2.8,1.6-3.9c1.1-1.1,2.4-1.6,3.9-1.6h33.8c1.4,0,2.6,0.5,3.8,1.6s1.7,2.4,1.7,3.9v0.7c-0.1,0.4-0.2,0.9-0.3,1.5s-0.4,1.8-0.9,3.6s-1,3.6-1.7,5.3s-1.7,3.8-3.1,6.1s-2.9,4.5-4.6,6.6c5.4,1.8,9.7,5.4,12.7,10.6c3,5.2,4.5,10.9,4.5,17.1c0,7.7-2.5,14.4-7.5,20.1c-5,5.6-11.2,8.4-18.5,8.4c-5.1,0-9.8-1.5-14-4.6c-4.2-3.1-7.3-7-9.3-11.8c-0.1-0.2-0.1-0.3-0.2-0.5c0-0.2-0.1-0.3-0.1-0.4s-0.1-0.2-0.1-0.4c0-0.2-0.1-0.3-0.1-0.5v-0.5c0-1.1,0.3-2.1,0.9-3.1C408.5,1040,409.3,1039.4,410.3,1039L410.3,1039z"/>
	  </g>
	  <g
			:class="$style.hour"
			:data-current="currentHour === 14 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(14) ? '' : null"
		>
	  	<path d="M259.1,929.5c1.1-1.1,2.4-1.6,3.9-1.6s2.8,0.5,3.9,1.6s1.6,2.4,1.6,3.9v73.6c0,1.5-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9v-73.6C257.5,931.9,258.1,930.6,259.1,929.5z"/>
	  	<path d="M336.9,977.5c1.1,1.1,1.6,2.4,1.6,3.9s-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6h-4.8v20.2c0,1.5-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9v-20.2h-28.3c-1.5,0-2.8-0.6-3.9-1.8c-1.1-1.2-1.6-2.4-1.6-3.7v-7.1c0-1.2,0.3-2.2,0.9-3.1l28-40.7c1.1-1.6,2.7-2.4,4.6-2.4h5.8c1.5,0,2.8,0.6,3.9,1.8s1.6,2.4,1.6,3.7v42.3h4.8C334.6,975.9,335.9,976.5,336.9,977.5L336.9,977.5z M317.2,975.9V943l-22.6,32.9H317.2L317.2,975.9z"/>
	  </g>
	  <g
			:class="$style.hour"
			:data-current="currentHour === 15 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(15) ? '' : null"
		>
	  	<path d="M158.5,850.2c1.1-1.1,2.4-1.6,3.9-1.6s2.8,0.5,3.9,1.6s1.6,2.4,1.6,3.9v73.6c0,1.5-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9v-73.6C156.9,852.6,157.4,851.3,158.5,850.2z"/>
	  	<path d="M187.4,910.2c0.2-0.1,0.5-0.2,0.7-0.2c0.2-0.1,0.5-0.1,0.7-0.2s0.5-0.1,0.8-0.1c1.1,0,2.1,0.3,3,0.9s1.6,1.4,2.1,2.4c0.9,2.1,2.1,3.9,3.4,5.4c1.4,1.5,2.9,2.7,4.5,3.5s3.3,1.2,4.9,1.2c3.8,0,7.2-1.9,10.2-5.7s4.6-8.6,4.6-14.5s-1.5-10.8-4.6-14.5s-6.5-5.7-10.2-5.7c-2.3,0-4.5,0.6-6.7,1.9s-4.1,3.2-5.9,5.8c-0.4,0.5-0.8,1-1.3,1.3s-1,0.6-1.6,0.8c-0.5,0.2-1.1,0.3-1.7,0.3c-1.3,0-2.4-0.4-3.4-1.3c-1.5-1.2-2.2-2.8-2.1-4.7l2.9-33.1c0.1-1.5,0.8-2.7,1.8-3.6s2.3-1.4,3.7-1.4h29.4c1.5,0,2.8,0.5,3.9,1.6s1.6,2.4,1.6,3.9s-0.5,2.8-1.6,3.9s-2.4,1.6-3.9,1.6h-24.4l-1.3,14.4c1.6-0.8,3.3-1.4,5.1-1.8s3.6-0.6,5.3-0.6c7.5,0,13.7,3.1,18.6,9.4c4.9,6.2,7.3,13.5,7.3,21.9s-2.4,15.7-7.3,21.9c-4.9,6.2-11.1,9.4-18.6,9.4c-5.1,0-9.6-1.5-13.7-4.7c-4.1-3.1-7.2-7.1-9.3-12c-0.3-0.7-0.5-1.4-0.5-2.2c0-1.1,0.3-2.1,0.9-3S186.4,910.6,187.4,910.2L187.4,910.2z"/>
	  </g>
	  <g
			:class="$style.hour"
			:data-current="currentHour === 16 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(16) ? '' : null"
		>
	  	<path d="M78.7,747.8c1.1-1.1,2.4-1.6,3.9-1.6s2.8,0.5,3.9,1.6s1.6,2.4,1.6,3.9v73.6c0,1.5-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9v-73.6C77.1,750.1,77.6,748.8,78.7,747.8z"/>
	  	<path d="M114.5,784.4c2.1-1.8,4.5-3.2,7.2-4.2s5.6-1.4,8.6-1.4c7.1,0,13.1,2.6,17.9,7.9s7.2,11.5,7.2,18.5s-2.2,12.9-6.6,18.3c-4.4,5.4-10.3,8.1-17.8,8.1c-2.7,0-5.4-0.6-8.1-1.7s-5.4-2.9-8-5.3s-4.9-5.8-6.8-10.2s-3.3-9.4-4.1-15.2c-0.2-1.7-0.3-3.4-0.4-5.2s-0.2-3.7-0.2-5.6c0-7.4,0.9-14.1,2.6-19.9s4.1-10.3,6.9-13.6c2.8-3.2,5.8-5.7,8.8-7.3c3.1-1.7,6.1-2.5,9.2-2.5c2.1,0,4.2,0.2,6.1,0.8s3.6,1.2,4.9,2.1s2.6,1.7,3.7,2.5s2,1.7,2.7,2.7c0.7,1,1.2,1.7,1.5,2.1s0.5,0.8,0.7,1.1c0.1,0.4,0.3,0.8,0.4,1.2s0.2,0.9,0.2,1.3c0,2.2-1,3.8-2.9,4.8c-0.8,0.5-1.7,0.7-2.5,0.7c-1,0-1.9-0.2-2.9-0.8s-1.6-1.2-2.1-2.1c-0.3-0.5-0.7-1.1-1.3-1.7s-1.6-1.4-3.3-2.3s-3.4-1.4-5.3-1.4c-0.5,0-1.1,0.1-1.8,0.3s-1.6,0.6-2.6,1.1s-2.1,1.2-3.1,2.1s-2,2-3,3.5s-1.9,3.2-2.7,5.1s-1.5,4.2-2.1,7C115.1,778.1,114.7,781.1,114.5,784.4L114.5,784.4z M117.3,807.9c0.9,2.6,2,4.8,3.3,6.6c1.3,1.8,2.6,3.1,3.8,3.9c1.3,0.8,2.5,1.4,3.5,1.7c1,0.3,2.1,0.5,3.1,0.5c4,0,7.2-1.5,9.6-4.5s3.7-6.6,3.7-10.9s-1.4-7.8-4.2-10.9c-2.8-3-6.1-4.5-9.8-4.5c-4.1,0-7.3,1.3-9.6,3.9s-3.4,7.1-3.4,13.6C117.3,807.3,117.3,807.9,117.3,807.9z"/>
	  </g>
	  <g
			:class="$style.hour"
			:data-current="currentHour === 17 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(17) ? '' : null"
		>
	  	<path d="M29.5,628c1.1-1.1,2.4-1.6,3.9-1.6s2.8,0.5,3.9,1.6c1.1,1.1,1.6,2.4,1.6,3.9v73.6c0,1.5-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6s-1.6-2.4-1.6-3.9v-73.6C27.9,630.3,28.5,629,29.5,628z"/>
	  	<path d="M54.6,635.8c-1.1-1.1-1.6-2.4-1.6-3.9s0.5-2.8,1.6-3.9c1.1-1.1,2.4-1.6,3.9-1.6h41.3c1.5,0,2.8,0.6,3.9,1.8c1.1,1.2,1.6,2.4,1.6,3.7v6.7c0,0.6-0.1,1.2-0.3,1.8s-0.5,1.1-0.9,1.5s-0.7,0.8-1,1.2c-0.5,0.2-1,0.6-1.6,1.1s-1.7,1.6-3.3,3.3c-1.6,1.7-3.1,3.5-4.5,5.5s-3,4.6-4.7,8s-3.2,6.9-4.4,10.7c-1.2,3.8-2.3,8.3-3.1,13.6c-0.8,5.3-1.3,10.8-1.3,16.7v3.7c0,1.5-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9v-3.7c0-7,0.6-13.8,1.7-20.2s2.6-11.9,4.2-16.3c1.7-4.4,3.7-8.6,5.9-12.6c2.3-4,4.3-7,6.1-9.2c1.8-2.2,3.6-4.2,5.5-6H58.5C56.9,637.4,55.6,636.8,54.6,635.8L54.6,635.8z"/>
	  </g>
	  <g
			:class="$style.hour"
			:data-current="currentHour === 18 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(18) ? '' : null"
		>
	  	<path d="M12.4,499.4c1.1-1.1,2.4-1.6,3.9-1.6s2.8,0.5,3.9,1.6c1.1,1.1,1.6,2.4,1.6,3.9v73.6c0,1.5-0.5,2.8-1.6,3.9s-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6s-1.6-2.4-1.6-3.9v-73.6C10.8,501.7,11.4,500.4,12.4,499.4L12.4,499.4z"/>
	  	<path d="M79.4,537.8c2,1.6,3.7,3.5,5.1,5.8c1.4,2.3,2.4,4.5,3.1,6.8c0.6,2.3,0.9,4.7,0.9,7.1c0,6.9-2.3,12.9-7,18c-4.7,5.1-10.9,7.7-18.7,7.7s-13.8-2.8-18.6-8.3S37,563.1,37,556.2c0-7.8,2.8-14.4,8.3-19.9c-4.5-4.5-6.8-10-6.8-16.4s2.3-11.9,7-16.3s10.4-6.7,17.2-6.7s12.2,2.2,17,6.6s7.2,9.9,7.2,16.4C87,525.9,84.5,531.9,79.4,537.8L79.4,537.8z M55.2,542.2c-1.8,1.3-3.5,3.1-4.9,5.3c-1.5,2.3-2.2,5.1-2.2,8.6c0,4.2,1.4,8,4.2,11.2c2.8,3.3,6.3,4.9,10.5,4.9c4.7,0,8.3-1.4,10.9-4.2s3.8-6.3,3.8-10.5c0-2.8-0.7-5.3-2-7.6c-1.3-2.3-3.2-4-5.6-5.1c-1.8-0.8-3.9-1.3-6.2-1.3c-1.5,0-2.9-0.1-4.3-0.3C57.9,542.9,56.5,542.6,55.2,542.2z M68.7,532.9c1.1-1.1,2.1-2.2,3.2-3.5s2-2.8,2.8-4.6c0.8-1.8,1.3-3.5,1.3-5c0-3.2-1.3-6-3.8-8.4s-5.7-3.6-9.4-3.6c-4,0-7.2,1.2-9.6,3.5c-2.4,2.3-3.6,5.2-3.6,8.5c0,4.6,2,8.1,5.9,10.5c2.4,1.5,5.1,2.2,8.3,2.2C65.3,532.5,67,532.6,68.7,532.9L68.7,532.9z"/>
	  </g>
	  <g
			:class="$style.hour"
			:data-current="currentHour === 19 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(19) ? '' : null"
		>
	  	<path d="M29.5,370.8c1.1-1.1,2.4-1.6,3.9-1.6s2.8,0.5,3.9,1.6c1.1,1.1,1.6,2.4,1.6,3.9v73.6c0,1.5-0.5,2.8-1.6,3.9s-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9v-73.6C27.9,373.2,28.4,371.9,29.5,370.8z"/>
	  	<path d="M94.2,415.6c-4.1,3.7-9.3,5.5-15.6,5.5c-7.1,0-13.1-2.6-17.9-7.9c-4.8-5.3-7.2-11.5-7.2-18.5s2.2-12.9,6.5-18.3s10.2-8.1,17.8-8.1c2.7,0,5.4,0.6,8.2,1.8c2.8,1.2,5.4,3,8.1,5.4c2.6,2.4,4.9,5.8,6.8,10.3s3.3,9.5,4,15.3c0.4,3.3,0.6,6.8,0.6,10.5c0,7.4-0.9,14.1-2.6,19.9s-4.1,10.3-6.9,13.6c-2.8,3.2-5.8,5.6-8.8,7.3c-3.1,1.6-6.1,2.5-9.2,2.5c-2.5,0-4.9-0.4-7.2-1.1s-4.1-1.6-5.5-2.6c-1.4-1-2.6-2.1-3.8-3.3s-1.9-2.2-2.3-2.7s-0.7-1-0.9-1.5c-0.4-0.8-0.6-1.6-0.6-2.5c0-2.2,1-3.8,2.9-4.8c0.8-0.5,1.7-0.7,2.5-0.7c1,0,1.9,0.2,2.9,0.8c0.9,0.5,1.6,1.2,2.1,2.1c0.3,0.5,0.7,1.1,1.3,1.7c0.5,0.6,1.6,1.4,3.3,2.3c1.7,0.9,3.4,1.4,5.3,1.4c0.6,0,1.4-0.1,2.2-0.5s1.9-0.8,3.2-1.5s2.4-1.7,3.6-3.1s2.3-3,3.3-5s2-4.5,2.7-7.6C93.5,422.9,93.9,419.4,94.2,415.6L94.2,415.6z M91.5,392.3c-0.9-2.6-2-4.8-3.3-6.7c-1.3-1.8-2.6-3.2-3.9-4c-1.3-0.8-2.5-1.4-3.6-1.8s-2.1-0.5-3.1-0.5c-4,0-7.2,1.5-9.6,4.5c-2.4,3-3.6,6.6-3.6,10.9s1.4,7.8,4.2,10.9c2.8,3,6.1,4.5,9.8,4.5c4.1,0,7.3-1.3,9.6-3.9c2.3-2.6,3.4-7.1,3.4-13.6L91.5,392.3L91.5,392.3z"/>
	  </g>
	  <g
			:class="$style.hour"
			:data-current="currentHour === 20 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(20) ? '' : null"
		>
	  	<path d="M69.5,276.3c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9c0-7.4,2.3-13.2,6.8-17.5c4.6-4.3,10.1-6.4,16.6-6.4s12.3,2.4,16.7,7.2s6.6,10.7,6.6,17.6c0,4.4-1,8.2-3.1,11.4s-5.3,6.5-9.8,9.8c-0.6,0.5-1.5,1.1-2.6,2.1c-7.5,5.6-12.4,9.9-14.8,12.9c-2.4,3-3.8,7.5-4.4,13.6h31.7c1.5,0,2.8,0.5,3.9,1.6c1.1,1.1,1.6,2.4,1.6,3.9s-0.5,2.8-1.6,3.9s-2.4,1.6-3.9,1.6H66.4c-1.3,0-2.5-0.5-3.7-1.6c-1.2-1.1-1.8-2.4-1.8-3.9c0-2.5,0.1-4.9,0.4-7.1c0.3-2.2,0.6-4.2,0.9-6c0.3-1.8,0.9-3.6,1.8-5.4c0.8-1.8,1.6-3.3,2.2-4.5s1.7-2.6,3.1-4.2c1.4-1.6,2.6-2.8,3.4-3.8c0.9-1,2.3-2.2,4.2-3.7c1.9-1.5,3.4-2.7,4.6-3.6s2.9-2.2,5.2-4c0.3-0.1,0.5-0.3,0.6-0.3c2.7-2.1,4.6-3.7,5.8-4.9s1.9-2.3,2.2-3.3s0.5-2.4,0.5-4.2c0-4.1-1.2-7.5-3.4-10s-5.2-3.8-8.8-3.8c-8.3,0-12.4,4.3-12.4,12.9C71.1,273.9,70.6,275.2,69.5,276.3L69.5,276.3z"/>
	  	<path d="M169.1,270.9c2.1,6.5,3.2,13.4,3.2,20.8s-1.1,14.3-3.2,20.8s-5.4,11.8-9.8,16.1s-9.5,6.4-15.2,6.4s-10.8-2.1-15.2-6.4c-4.4-4.2-7.7-9.6-9.9-16.1s-3.3-13.4-3.3-20.8s1.1-14.3,3.3-20.8c2.2-6.5,5.5-11.8,9.9-16.1s9.5-6.4,15.2-6.4s10.8,2.1,15.2,6.4C163.7,259.1,166.9,264.5,169.1,270.9z M155.7,314.8c3.7-6,5.6-13.8,5.6-23.1s-1.9-17-5.6-23.1c-3.7-6-7.6-9.1-11.7-9.1s-8,3-11.7,9.1c-3.8,6-5.6,13.8-5.6,23.1s1.9,17,5.6,23.1c3.8,6,7.7,9.1,11.7,9.1S152,320.8,155.7,314.8z"/>
	  </g>
	  <g
			:class="$style.hour"
			:data-current="currentHour === 21 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(21) ? '' : null"
		>
	  	<path d="M166.3,173.8c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6s-1.6-2.4-1.6-3.9c0-7.4,2.3-13.2,6.8-17.5c4.6-4.3,10.1-6.4,16.6-6.4s12.3,2.4,16.7,7.2c4.4,4.8,6.6,10.7,6.6,17.6c0,4.4-1,8.2-3.1,11.4s-5.3,6.5-9.8,9.8c-0.6,0.5-1.5,1.1-2.6,2.1c-7.5,5.6-12.4,9.9-14.8,12.9c-2.4,3-3.8,7.5-4.4,13.6h31.7c1.5,0,2.8,0.5,3.9,1.6c1.1,1.1,1.6,2.4,1.6,3.9s-0.5,2.8-1.6,3.9s-2.4,1.6-3.9,1.6h-37.5c-1.3,0-2.6-0.5-3.7-1.6s-1.8-2.4-1.8-3.9c0-2.5,0.1-4.9,0.4-7.1c0.3-2.2,0.6-4.2,0.9-6c0.3-1.8,0.9-3.6,1.8-5.4c0.8-1.8,1.6-3.3,2.2-4.5c0.6-1.2,1.7-2.6,3.1-4.2c1.4-1.6,2.6-2.8,3.4-3.8c0.9-1,2.3-2.2,4.2-3.7c1.9-1.5,3.4-2.7,4.6-3.6c1.1-0.9,2.9-2.2,5.2-4c0.3-0.1,0.5-0.3,0.6-0.3c2.7-2.1,4.6-3.7,5.8-4.9s1.9-2.3,2.2-3.3c0.3-1,0.5-2.4,0.5-4.2c0-4.1-1.1-7.5-3.4-10s-5.2-3.8-8.9-3.8c-8.3,0-12.4,4.3-12.4,12.9C167.9,171.5,167.3,172.8,166.3,173.8L166.3,173.8z"/>
	  	<path d="M224.1,148.6c1.1-1.1,2.4-1.6,3.9-1.6s2.8,0.5,3.9,1.6c1.1,1.1,1.6,2.4,1.6,3.9V226c0,1.5-0.5,2.8-1.6,3.9s-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6s-1.6-2.4-1.6-3.9v-73.6C222.4,150.9,223,149.6,224.1,148.6z"/>
	  </g>
	  <g
			:class="$style.hour"
			:data-current="currentHour === 22 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(22) ? '' : null"
		>
	  	<path d="M253.7,94.9c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6s-1.6-2.4-1.6-3.9c0-7.4,2.3-13.2,6.8-17.5s10.1-6.4,16.6-6.4s12.3,2.4,16.7,7.2c4.4,4.8,6.6,10.7,6.6,17.6c0,4.4-1,8.2-3.1,11.4c-2.1,3.2-5.3,6.5-9.8,9.8c-0.6,0.5-1.5,1.2-2.6,2.1c-7.5,5.6-12.5,9.9-14.8,12.9c-2.4,3-3.8,7.5-4.4,13.6H288c1.5,0,2.8,0.5,3.9,1.6c1.1,1.1,1.6,2.4,1.6,3.9s-0.5,2.8-1.6,3.9s-2.4,1.6-3.9,1.6h-37.5c-1.3,0-2.6-0.5-3.7-1.6s-1.8-2.4-1.8-3.9c0-2.5,0.1-4.9,0.4-7.1c0.3-2.2,0.6-4.2,0.9-6c0.3-1.8,0.9-3.6,1.8-5.4c0.8-1.8,1.6-3.3,2.2-4.5c0.6-1.2,1.7-2.6,3.1-4.2c1.4-1.6,2.6-2.8,3.4-3.8s2.3-2.2,4.2-3.7c1.9-1.5,3.5-2.7,4.6-3.6c1.1-0.9,2.9-2.2,5.2-4c0.3-0.2,0.5-0.3,0.6-0.3c2.7-2.1,4.6-3.7,5.8-4.9s1.9-2.3,2.2-3.3s0.5-2.4,0.5-4.2c0-4.1-1.1-7.5-3.5-10c-2.3-2.5-5.2-3.8-8.9-3.8c-8.3,0-12.4,4.3-12.4,12.9C255.3,92.5,254.7,93.8,253.7,94.9L253.7,94.9z"/>
	  	<path d="M311.9,94.9c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6s-1.6-2.4-1.6-3.9c0-7.4,2.3-13.2,6.8-17.5s10.1-6.4,16.6-6.4s12.3,2.4,16.7,7.2c4.4,4.8,6.6,10.7,6.6,17.6c0,4.4-1,8.2-3.1,11.4c-2.1,3.2-5.3,6.5-9.8,9.8c-0.6,0.5-1.5,1.2-2.6,2.1c-7.5,5.6-12.5,9.9-14.8,12.9c-2.4,3-3.8,7.5-4.4,13.6h31.7c1.5,0,2.8,0.5,3.9,1.6c1.1,1.1,1.6,2.4,1.6,3.9s-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6h-37.5c-1.3,0-2.5-0.5-3.7-1.6s-1.8-2.4-1.8-3.9c0-2.5,0.1-4.9,0.4-7.1c0.3-2.2,0.6-4.2,0.9-6c0.3-1.8,0.9-3.6,1.8-5.4c0.8-1.8,1.6-3.3,2.2-4.5s1.7-2.6,3.1-4.2c1.4-1.6,2.6-2.8,3.5-3.8s2.3-2.2,4.2-3.7c1.9-1.5,3.5-2.7,4.6-3.6c1.1-0.9,2.9-2.2,5.2-4c0.3-0.2,0.5-0.3,0.6-0.3c2.7-2.1,4.6-3.7,5.8-4.9s1.9-2.3,2.2-3.3s0.5-2.4,0.5-4.2c0-4.1-1.1-7.5-3.5-10c-2.3-2.5-5.2-3.8-8.9-3.8c-8.3,0-12.4,4.3-12.4,12.9C313.5,92.5,313,93.8,311.9,94.9L311.9,94.9z"/>
	  </g>
	  <g
			:class="$style.hour"
			:data-current="currentHour === 23 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(23) ? '' : null"
		>
	  	<path d="M373.9,44.8c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6s-1.6-2.4-1.6-3.9c0-7.4,2.3-13.2,6.8-17.5s10.1-6.4,16.6-6.4s12.3,2.4,16.7,7.2s6.6,10.7,6.6,17.6c0,4.4-1,8.2-3.1,11.4s-5.3,6.5-9.8,9.8c-0.6,0.5-1.5,1.1-2.6,2.1c-7.5,5.6-12.5,9.9-14.8,12.9c-2.4,3-3.8,7.5-4.4,13.6h31.7c1.5,0,2.8,0.5,3.9,1.6c1.1,1.1,1.6,2.4,1.6,3.9s-0.5,2.8-1.6,3.9s-2.4,1.6-3.9,1.6h-37.5c-1.3,0-2.5-0.5-3.7-1.6s-1.8-2.4-1.8-3.9c0-2.5,0.1-4.9,0.4-7.1c0.3-2.2,0.6-4.2,0.9-6c0.3-1.8,0.9-3.6,1.8-5.4s1.6-3.3,2.2-4.5s1.7-2.6,3.1-4.2c1.4-1.6,2.6-2.8,3.5-3.8s2.3-2.2,4.2-3.7c1.9-1.5,3.5-2.7,4.6-3.6c1.1-0.9,2.9-2.2,5.2-4c0.3-0.2,0.5-0.3,0.6-0.3c2.7-2.1,4.6-3.7,5.8-4.9c1.1-1.2,1.9-2.3,2.2-3.3s0.5-2.4,0.5-4.2c0-4.1-1.1-7.5-3.5-10c-2.3-2.5-5.2-3.8-8.9-3.8c-8.3,0-12.4,4.3-12.4,12.9C375.5,42.4,375,43.7,373.9,44.8L373.9,44.8z"/>
	  	<path d="M424.8,79.8c0.7-0.3,1.4-0.5,2.1-0.5s1.4,0.1,2.1,0.4c0.7,0.3,1.3,0.7,1.8,1.2s0.9,1.1,1.1,1.7c1.3,3,3.1,5.4,5.5,7.1c2.4,1.8,4.9,2.6,7.7,2.6c3.9,0,7.4-1.7,10.4-5.1c3-3.4,4.5-7.5,4.5-12.4c0-5.4-1.3-9.7-3.9-13c-2.6-3.3-6-4.9-10.1-4.9c-1.9,0-4.2,0.7-6.8,2c-0.7,0.4-1.5,0.6-2.4,0.6c-0.5,0-1.1-0.1-1.7-0.2c-0.2-0.2-0.5-0.3-0.8-0.4s-0.5-0.2-0.8-0.4s-0.4-0.3-0.6-0.5c-0.2-0.2-0.4-0.4-0.6-0.6s-0.3-0.5-0.4-0.8h-0.1v-0.1c-0.4-0.7-0.6-1.5-0.6-2.4c0-0.5,0.1-1.1,0.2-1.7c0.2-0.6,0.6-1.2,1-1.8s1-1,1.7-1.3C442.8,44.8,448.6,38,452,29h-26.7c-1.5,0-2.8-0.5-3.9-1.6s-1.6-2.4-1.6-3.9s0.5-2.8,1.6-3.9c1.1-1.1,2.4-1.6,3.9-1.6h33.8c1.4,0,2.6,0.5,3.8,1.6s1.7,2.4,1.7,3.9v0.7c-0.1,0.4-0.2,0.9-0.3,1.5s-0.4,1.8-0.9,3.6s-1,3.6-1.7,5.3s-1.7,3.8-3.1,6.1s-2.9,4.5-4.6,6.6c5.4,1.8,9.7,5.4,12.7,10.6c3,5.2,4.5,10.9,4.5,17.1c0,7.7-2.5,14.4-7.5,20.1c-5,5.6-11.2,8.4-18.5,8.4c-5.1,0-9.8-1.5-14-4.6c-4.2-3.1-7.3-7-9.3-11.8c-0.1-0.2-0.1-0.3-0.2-0.5c0-0.2-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.4c0-0.2-0.1-0.3-0.1-0.5v-0.5c0-1.1,0.3-2.1,0.9-3.1C423,80.8,423.9,80.1,424.8,79.8L424.8,79.8z"/>
	  </g>
	  <g
			:class="$style.hour"
			:data-current="currentHour === 24 ? '' : null"
			:data-within-openings-hours="withinOpeningHours(24) ? '' : null"
		>
	  	<path d="M500.3,28.2c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6s-1.6-2.4-1.6-3.9c0-7.4,2.3-13.2,6.8-17.5s10.1-6.4,16.6-6.4s12.3,2.4,16.7,7.2c4.4,4.8,6.6,10.7,6.6,17.6c0,4.4-1,8.2-3.1,11.4c-2.1,3.2-5.3,6.5-9.8,9.8c-0.6,0.5-1.5,1.2-2.6,2.1c-7.5,5.6-12.5,9.9-14.8,12.9c-2.4,3-3.8,7.5-4.4,13.6h31.7c1.5,0,2.8,0.5,3.9,1.6c1.1,1.1,1.6,2.4,1.6,3.9s-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6h-37.5c-1.3,0-2.5-0.5-3.7-1.6s-1.8-2.4-1.8-3.9c0-2.5,0.1-4.9,0.4-7.1c0.3-2.2,0.6-4.2,0.9-6c0.3-1.8,0.9-3.6,1.8-5.4c0.8-1.8,1.6-3.3,2.2-4.5s1.7-2.6,3.1-4.2c1.4-1.6,2.6-2.8,3.5-3.8c0.9-1,2.3-2.2,4.2-3.7c1.9-1.5,3.5-2.7,4.6-3.6c1.2-0.9,2.9-2.2,5.2-4c0.3-0.2,0.5-0.3,0.6-0.3c2.7-2.1,4.6-3.7,5.8-4.9c1.2-1.2,1.9-2.3,2.2-3.3c0.3-1,0.5-2.4,0.5-4.2c0-4.1-1.2-7.5-3.5-10s-5.2-3.8-8.8-3.8c-8.3,0-12.4,4.3-12.4,12.9C501.9,25.8,501.4,27.1,500.3,28.2L500.3,28.2z"/>
	  	<path d="M600.4,51c1.1,1.1,1.6,2.4,1.6,3.9s-0.5,2.8-1.6,3.9s-2.4,1.6-3.9,1.6h-4.8v20.2c0,1.5-0.5,2.8-1.6,3.9c-1.1,1.1-2.4,1.6-3.9,1.6s-2.8-0.5-3.9-1.6c-1.1-1.1-1.6-2.4-1.6-3.9V60.4h-28.3c-1.5,0-2.8-0.6-3.9-1.8c-1.1-1.2-1.6-2.4-1.6-3.7v-7.1c0-1.2,0.3-2.2,0.9-3.1l28-40.7c1.2-1.6,2.7-2.4,4.6-2.4h5.8c1.5,0,2.8,0.6,3.9,1.8c1.1,1.2,1.6,2.4,1.6,3.7v42.3h4.8C598,49.4,599.3,49.9,600.4,51L600.4,51z M580.6,49.4V16.5l-22.6,32.9H580.6z"/>
	  </g>
	  </svg>
</template>

<script setup>
import { range } from 'lodash-es'

const props = defineProps({
	currentHour: Number,
	openingHours: {
		type: Object,
		required: false,
	}
})

const withinOpeningHours = (number) => {
	if (props.openingHours) {
		let startHour = parseInt(props.openingHours?.openingTime?.split(':')[0])
		const startMinutes = parseInt(props.openingHours?.openingTime?.split(':')[1])
		let endHour = parseInt(props.openingHours?.closingTime?.split(':')[0]) + 1
		const endMinutes = parseInt(props.openingHours?.closingTime?.split(':')[1])

		if (endMinutes > 0) {
			endHour++
		}

		if (startHour > endHour) {
			return range(startHour, 24).includes(number) || range(0, endHour).includes(number)
		} else {
			return range(startHour, endHour).includes(number)
		}
	} else {
		return false
	}
}

</script>

<style module>
.hour:not([data-current]):not([data-within-openings-hours]) {
	display: none;
}

.hour[data-within-openings-hours]:not([data-current]) * {
	/* fill: var(--color--gray) */
}

.hour[data-current] * {
	/* fill: var(--color--pink); */
}
</style>
