<template>
  <PagesIndexComponent
    :pageData="data"
    :aboutPageData="aboutPageData"
    :participantsPageData="participantsPageData"
    :programsPageData="programsPageData"
    :programEntriesData="programEntriesData"
    :publicationsPageData="publicationsPageData"
    :publicationEntriesData="publicationEntriesData"
  />
</template>

<script setup>
// 1
const queryPath = queryHomePage()
const { data, error } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryPath } }
  )
})

if (!data?.value?.data?.id || error?.value) {
  throw createError({ statusCode: 404 })
}

pageSetHead(data)

// 2
const { data: aboutPageData, error: aboutError } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryAboutPageThumbnail() } }
  )
})

// 3
const { data: participantsPageData, error: participantsPageError } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryParticipantsPageCurrentParticipants() } }
  )
})

// 4
const { data: programsPageData, error: programPageError } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryProgramsPageThumbnail() } }
  )
})

// 5
const queryProgramEntriesData = {
  ...baseRouteGetPublicationStateQuery(),
  filters: {
    $or: [
      { date_time_start: { $gte: dayJs().format('YYYY-MM-DD') } },
      { date_time_end: { $gte: dayJs().format('YYYY-MM-DD') } }
    ]
  },
  sort: ['date_time_start:asc', 'date_time_end:asc'],
  pagination: {
    page: 1,
    pageSize: 3
  }
}

const { data: programEntriesData, error: programEntriesError } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryProgramEntryThumbnails(queryProgramEntriesData) } }
  )
})

// 6
const { data: publicationsPageData, error: publicationsPageError } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryPublicationsPageThumbnail() } }
  )
})

// 7
const { data: publicationEntriesData, error: publicationEntriesError } = await useAsyncData(() => {
  return $fetch('/api/strapi-rest',
    { query: { path: queryPublicationEntryThumbnails({ ...baseRouteGetPublicationStateQuery(), pageSize: 3 }) } }
  )
})
</script>
