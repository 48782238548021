import qs from 'qs'

export const queryHomePage = () => {
  const query = qs.stringify(
    {
      fields: ['title'],
      populate: {
        titles: {
          fields: [
            'i18n_string_en',
            'i18n_string_nl'
          ]
        },
        bulletin_board_texts: {
          populate: {
            fields: [
              'i18n_string_en',
              'i18n_string_nl'
            ]
          }
        },
        cover_image: true
      }
    },
    {
      encodeValuesOnly: true
    }
  )

  return `home-page?${query}`
}
