<template>
  <div
    :class="$style.root"
  >
    <client-only>
      <div
        :class="$style.container"
        :style="{
          '--width-percentage': `${widthPercentage}%`,
        }"
      >
        <PagesIndexClockDotsSvg
          :class="$style.svgOutline"
          :currentHour="hours.value"
          :openingHours="openingsHoursForToday"
        />
        <PagesIndexClockNumbersSvg
          :class="$style.svgOutline"
          :currentHour="hours.value"
          :openingHours="openingsHoursForToday"
        />

        <div
          :class="$style.second"
          :style="{ '--deg': `${seconds.deg}deg`, '--steps': seconds.value }"
          @transitionend.once="onTransitionEnd"
          :data-value="seconds.value"
          data-smooth-transition
        >
          <div :class="$style.wiserContent">
            <SvgoClockSecond :class="$style.svgSecond" />
          </div>
        </div>
        <div
          :class="$style.minute"
          :style="{ '--deg': `${minutes.deg}deg`, '--steps': minutes.value }"
          @transitionend.once="onTransitionEnd"
          :data-value="minutes.value"
          data-smooth-transition
        >
          <div :class="$style.wiserContent">
            <SvgoClockMinute :class="$style.svgMinute" />
          </div>
        </div>
        <div
          :class="$style.hour"
          :style="{ '--deg': `${hours.deg}deg`, '--steps': hours.value }"
          @transitionend.once="onTransitionEnd"
          :data-value="hours.value"
          data-smooth-transition
        >
          <div :class="$style.wiserContent">
            <SvgoClockHour :class="$style.svgHour" />
          </div>
        </div>
      </div>
    </client-only>
  </div>
</template>

<script setup>
// 1
const { width: windowWidth, height: windowHeight } = useWindowSize()

const widthPercentage = computed(() => {
  const height = windowHeight.value
  const onePercentWidth = windowWidth.value / 100

  if ( windowWidth.value > windowHeight.value ) {
    return height / onePercentWidth
  } else {
    return 100
  }
})

const seconds = ref({ deg: 0, value: 0 })
const minutes = ref({ deg: 0, value: 0 })
const hours = ref({ deg: 0, value: 0 })

const setDegrees = () => {
  const date = new Date()
  const formattedDate = new Date(String(date.toLocaleString('en-EN', { timeZone: 'Europe/Amsterdam' })))

  seconds.value = {deg: ((formattedDate.getSeconds() / 60) * 360), value: formattedDate.getSeconds()}
  minutes.value = {deg: ((formattedDate.getMinutes() / 60) * 360), value: formattedDate.getMinutes()}
  hours.value = {deg: ((formattedDate.getHours() / 24) * 360), value: formattedDate.getHours()}
}

const interval = ref(null)
onMounted(() => {
  interval.value = setInterval(() => {
    setDegrees()
  }, 1)
})

onBeforeUnmount(() => {
  clearInterval(interval.value)
})

const onTransitionEnd = (e) => {
  e?.target?.removeAttribute('data-smooth-transition')
}

// 2
const mainStore = useMainStore()
const openingTimesData = await mainStore?.fetchOpeningTimesData()
const openingsHoursForToday = ref(openingTimesData?.openingsHoursForToday)
</script>

<style module lang="scss">
.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: calc(var(--100svh, 100vh) - var(--height--site-header));
}

.container {
  --width: calc((var(--width-percentage, 0%) - var(--height--site-header)) * .85);
  width: var(--width);
  aspect-ratio: 1;

  position: relative;

  transform: translateY(calc((var(--height--site-header) / 2) * -1));

  @media (max-width: $breakpoint-s) {
    --width: calc((var(--width-percentage, 0%)) * 1 - var(--unit--vertical) * 4);
  }
}

.svgOutline {
  position: absolute;
  top: 0%;
  left: 0%;
}

.svgOutline * {
  fill: var(--color--primary);
}

.wiser {
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(0%, -100%) rotate(var(--deg, 0deg));
  transform-origin: 0% 100%;

  height: 50%;

  display: flex;
}

.wiser[data-smooth-transition] {
  transition: transform .25s ease;
}

.second {
  composes: wiser;
}

.minute {
  composes: wiser;
}

.hour {
  composes: wiser;
}

.wiserContent {
  position: relative;
  flex: 1;
}

.wiserContent .svgMinute,
.wiserContent .svgHour {
  position: absolute;

  bottom: -10%;
  left: 50%;
  transform: translate(-50%, 0%);

  width: auto;
  height: 75%;
  overflow: visible;
}

.wiserContent .svgSecond {
  position: absolute;

  bottom: -10%;
  left: 50%;
  transform: translate(-50%, 0%);

  width: auto;
  height: 80%;
  overflow: visible;
}

.svgSecond {
  fill: var(--color--accent);
}

.svgMinute *,
.svgHour * {
  fill: none;
  stroke: var(--color--primary);
  stroke-width: 20;
  stroke-linecap: round;
}

.svgMinute * {
  stroke: var(--color--primary);
}
</style>
