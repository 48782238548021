<template>
  <div :class="$style.root">
    <div
      :class="$style.about"
      v-if="title || introduction"
    >
      <LayoutLinkWithBarComponent :to="{ name: 'publications' }">
        {{ title }}
      </LayoutLinkWithBarComponent>
    </div>

    <SharedThumbnailsPublicationsComponent
      :thumbnails="publicationEntriesData?.data"
    />

    <div
      v-if="introduction"
      :class="$style.introductionContainer"
    >
      <div :class="$style.introduction">
        <CoreTextComponent
          :string="introduction"
        />
        <!-- <LayoutHorizontalLineComponent /> -->
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  publicationsPageData: {
    type: Object,
    required: false
  },
  publicationEntriesData: {
    type: Object,
    required: false
  },
})

const localizedPublicationsPageData = computed(() => {
  return i18nGetLocalizedData(baseStrapiGetAttributes(props?.publicationsPageData))
})

const title = computed(() => {
  return localizedPublicationsPageData?.value?.titles?.current_locale || localizedPublicationsPageData?.value?.titles?.i18n_string_en
})

const introduction = computed(() => {
  return localizedPublicationsPageData?.value?.introductions?.current_locale || localizedPublicationsPageData?.value?.introductions?.i18n_richtext_en
})
</script>

<style module>
.root {
}

.about {
  display: flex;
  flex-direction: column;
  gap: var(--unit--vertical);

  padding: 0 var(--unit--horizontal--double);

  margin-bottom: var(--unit--vertical--tripple);
}

.introductionContainer {
  padding: 0 var(--unit--horizontal--double);
  margin-top: var(--unit--spacer);
}

.introduction {
  composes: container--default from global;

  display: flex;
  flex-direction: column;
  gap: var(--unit--spacer);
  text-align: center;
}
</style>
