<template>
	<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 635 635" style="enable-background:new 0 0 635 635;" xml:space="preserve">
		<path
			:class="$style.hour"
			:data-current="currentHour == 1 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(1) ? '' : null"
			d="M419.1,44.4c-3.9,14.6-18.9,23.3-33.6,19.4c-14.6-3.9-23.3-18.9-19.4-33.6C370,15.6,385,6.9,399.7,10.8C414.3,14.7,423,29.8,419.1,44.4L419.1,44.4z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 2 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(2) ? '' : null"
			d="M486.3,80c-7.6,13.1-24.3,17.6-37.4,10s-17.6-24.3-10-37.4s24.3-17.6,37.4-10l0,0C489.4,50.1,493.9,66.9,486.3,80L486.3,80L486.3,80z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 3 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(3) ? '' : null"
			d="M542,131.8c-10.7,10.7-28.1,10.7-38.8,0s-10.7-28.1,0-38.8s28.1-10.7,38.8,0l0,0C552.7,103.7,552.7,121.1,542,131.8L542,131.8L542,131.8z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 4 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(4) ? '' : null"
			d="M582.4,196.2c-13.1,7.6-29.9,3.1-37.4-10c-7.6-13.1-3.1-29.9,10-37.4s29.9-3.1,37.4,10l0,0C600,171.9,595.5,188.6,582.4,196.2L582.4,196.2z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 5 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(5) ? '' : null"
			d="M604.8,268.9c-14.6,3.9-29.7-4.8-33.6-19.4c-3.9-14.6,4.8-29.7,19.4-33.6c14.6-3.9,29.7,4.8,33.6,19.4C628.1,250,619.4,265,604.8,268.9L604.8,268.9z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 6 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(6) ? '' : null"
			d="M607.6,344.9c-15.1,0-27.4-12.3-27.4-27.4s12.3-27.4,27.4-27.4s27.4,12.3,27.4,27.4S622.7,344.9,607.6,344.9L607.6,344.9L607.6,344.9z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 7 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(7) ? '' : null"
			d="M590.6,419.1c-14.6-3.9-23.3-18.9-19.4-33.6c3.9-14.6,18.9-23.3,33.6-19.4c14.6,3.9,23.3,18.9,19.4,33.6C620.3,414.3,605.2,423,590.6,419.1L590.6,419.1z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 8 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(8) ? '' : null"
			d="M555,486.3c-13.1-7.6-17.6-24.3-10-37.4s24.3-17.6,37.4-10c13.1,7.6,17.6,24.3,10,37.4l0,0C584.9,489.4,568.1,493.9,555,486.3L555,486.3L555,486.3z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 9 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(9) ? '' : null"
			d="M503.2,542c-10.7-10.7-10.7-28.1,0-38.8s28.1-10.7,38.8,0s10.7,28.1,0,38.8l0,0C531.3,552.7,513.9,552.7,503.2,542L503.2,542L503.2,542z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 10 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(10) ? '' : null"
			d="M438.8,582.4c-7.6-13.1-3.1-29.9,10-37.4c13.1-7.6,29.9-3.1,37.4,10c7.6,13.1,3.1,29.9-10,37.4l0,0C463.1,600,446.4,595.5,438.8,582.4L438.8,582.4z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 11 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(11) ? '' : null"
			d="M366.1,604.8c-3.9-14.6,4.8-29.7,19.4-33.6c14.6-3.9,29.7,4.8,33.6,19.4c3.9,14.6-4.8,29.7-19.4,33.6C385,628.1,370,619.4,366.1,604.8L366.1,604.8z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 12 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(12) ? '' : null"
			d="M290.1,607.6c0-15.1,12.3-27.4,27.4-27.4s27.4,12.3,27.4,27.4S332.6,635,317.5,635S290.1,622.7,290.1,607.6L290.1,607.6L290.1,607.6z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 13 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(13) ? '' : null"
			d="M215.9,590.6c3.9-14.6,18.9-23.3,33.6-19.4c14.6,3.9,23.3,18.9,19.4,33.6c-3.9,14.6-18.9,23.3-33.6,19.4C220.7,620.3,212,605.2,215.9,590.6L215.9,590.6z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 14 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(14) ? '' : null"
			d="M148.7,555c7.6-13.1,24.3-17.6,37.4-10s17.6,24.3,10,37.4c-7.6,13.1-24.3,17.6-37.4,10l0,0C145.6,584.9,141.1,568.1,148.7,555L148.7,555z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 15 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(15) ? '' : null"
			d="M93,503.2c10.7-10.7,28.1-10.7,38.8,0s10.7,28.1,0,38.8s-28.1,10.7-38.8,0l0,0C82.3,531.3,82.3,513.9,93,503.2L93,503.2L93,503.2z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 16 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(16) ? '' : null"
			d="M52.6,438.8c13.1-7.6,29.9-3.1,37.4,10c7.6,13.1,3.1,29.9-10,37.4s-29.9,3.1-37.4-10l0,0C35,463.1,39.5,446.4,52.6,438.8L52.6,438.8z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 17 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(17) ? '' : null"
			d="M30.2,366.1c14.6-3.9,29.7,4.8,33.6,19.4s-4.8,29.7-19.4,33.6s-29.7-4.8-33.6-19.4C6.9,385,15.6,370,30.2,366.1L30.2,366.1z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 18 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(18) ? '' : null"
			d="M54.8,317.5c0,15.1-12.3,27.4-27.4,27.4S0,332.6,0,317.5s12.3-27.4,27.4-27.4S54.8,302.4,54.8,317.5z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 19 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(19) ? '' : null"
			d="M44.4,215.9c14.6,3.9,23.3,18.9,19.4,33.6s-18.9,23.3-33.6,19.4C15.6,265,6.9,250,10.8,235.3C14.7,220.7,29.8,212,44.4,215.9L44.4,215.9z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 20 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(20) ? '' : null"
			d="M80,148.7c13.1,7.6,17.6,24.3,10,37.4s-24.3,17.6-37.4,10s-17.6-24.3-10-37.4l0,0C50.1,145.6,66.9,141.1,80,148.7L80,148.7z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 21 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(21) ? '' : null"
			d="M131.8,93c10.7,10.7,10.7,28.1,0,38.8s-28.1,10.7-38.8,0S82.3,103.7,93,93l0,0C103.7,82.3,121.1,82.3,131.8,93L131.8,93L131.8,93z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 22 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(22) ? '' : null"
			d="M196.2,52.6c7.6,13.1,3.1,29.9-10,37.4c-13.1,7.6-29.9,3.1-37.4-10c-7.6-13.1-3.1-29.9,10-37.4l0,0C171.9,35,188.6,39.5,196.2,52.6L196.2,52.6z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 23 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(23) ? '' : null"
			d="M268.9,30.2c3.9,14.6-4.8,29.7-19.4,33.6s-29.7-4.8-33.6-19.4c-3.9-14.6,4.8-29.7,19.4-33.6C250,6.9,265,15.6,268.9,30.2L268.9,30.2z"
		/>
		<path
			:class="$style.hour"
			:data-current="currentHour == 24 ? '' : null"
			:data-outside-of-opening-hours="outsideOfOpeningHours(24) ? '' : null"
			d="M344.9,27.4c0,15.1-12.3,27.4-27.4,27.4s-27.4-12.3-27.4-27.4S302.4,0,317.5,0S344.9,12.3,344.9,27.4L344.9,27.4L344.9,27.4z"
		/>
	</svg>
</template>

<script setup>
import { range } from 'lodash-es'

const props = defineProps({
	currentHour: Number,
	openingHours: {
		type: Object,
		required: false,
	}
})

const outsideOfOpeningHours = (number) => {
	if (props.openingHours) {
		let startHour = parseInt(props.openingHours?.openingTime?.split(':')[0])
		const startMinutes = parseInt(props.openingHours?.openingTime?.split(':')[1])
		let endHour = parseInt(props.openingHours?.closingTime?.split(':')[0]) + 1
		const endMinutes = parseInt(props.openingHours?.closingTime?.split(':')[1])

		if ( endMinutes > 0 ) {
			endHour++
		}

		if (startHour > endHour) {
			return range(startHour, 24).includes(number) || range(0, endHour).includes(number) === false
		} else {
			return range(startHour, endHour).includes(number) === false
		}
	} else {
		return true
	}
}

</script>

<style module>
.hour[data-current],
.hour:not([data-outside-of-opening-hours]) {
	display: none;
}
</style>
